export function Caret({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 11 7">
      <path d="M1.5 1a.5.5 0 0 0-.354.853l3.985 4a.5.5 0 0 0 .707 0l4.016-4A.5.5 0 0 0 9.5 1z" />
    </svg>
  )
}
