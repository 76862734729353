export default function Print({ className }) {
  const classes = ["fill-current text-black-100"].concat(className)
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className={classes.join(" ")}>
      <g>
        <g>
          <path
            d="M44,13.7H4c-2.2,0-4,1.8-4,4v16.1c0,2.2,1.8,4,4,4h6.5v-7.1v-1.5h1.5h24.1h1.5v1.5v7.1H44c2.2,0,4-1.8,4-4
          V17.7C48,15.5,46.2,13.7,44,13.7z M38.5,21.3h-2.4c-1.1,0-2-0.9-2-2s0.9-2,2-2h2.4c1.1,0,2,0.9,2,2S39.6,21.3,38.5,21.3z"
          />
          <path
            d="M36.1,30.8V41c0,2.2-1.8,4-4,4H16c-2.2,0-4-1.8-4-4V30.8H36.1 M39.1,27.8h-3H11.9h-3v3V41c0,3.9,3.2,7,7,7H32
          c3.9,0,7-3.2,7-7V30.8V27.8L39.1,27.8z"
          />
          <g>
            <path d="M36.7,10.2V4c0-2.2-1.8-4-4-4H15.4c-2.2,0-4,1.8-4,4v6.2H36.7z" />
          </g>
        </g>
      </g>
    </svg>
  )
}
