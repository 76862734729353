import { deleteCookie, getCookie, setCookie } from "cookies-next"
export const memberKey = "nature.member"

export function getMemberToken(context = null) {
  let cookie

  if (context) {
    const { req, res } = context
    cookie = getCookie(memberKey, { req, res })
  } else {
    cookie = getCookie(memberKey)
  }

  if (!cookie) {
    return null
  }

  return cookie
}

export function setMemberCookie(token) {
  setCookie(memberKey, token, { sameSite: "strict", secure: true })
}

export function deleteMemberCookie() {
  deleteCookie(memberKey)
}

export function decodeToken(token) {
  return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString())
}
