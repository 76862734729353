export function Caret({ className }) {
  return (
    <svg
      viewBox="0 0 10 6"
      version="1.1"
      width="10"
      height="6"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-1131.000000, -496.000000)">
          <g transform="translate(1000.000000, 469.000000)">
            <path
              d="M133.5,26 L133.5,34 C133.5,34.444983 134.037601,34.6682784 134.352864,34.3542413 L138.352864,30.3697928 C138.548507,30.1749103 138.549123,29.8583264 138.354239,29.6626847 L134.354239,25.6471332 C134.039538,25.3312088 133.5,25.5540797 133.5,26 Z"
              transform="translate(136.000000, 30.000000) rotate(90.000000) translate(-136.000000, -30.000000)"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
