export default function Map({ className = "", fillColor = "#101010" }) {
  return (
    <svg
      viewBox="0 0 24 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          className={className}
          transform="translate(-1203.000000, -345.000000)"
          fill={fillColor}
          fillRule="nonzero">
          <g transform="translate(70.000000, 40.000000)">
            <g transform="translate(30.000000, 270.000000)">
              <g transform="translate(1088.000000, 20.000000)">
                <g transform="translate(15.000000, 15.000000)">
                  <path d="M0.769230769,19.2307692 C0.608396291,19.2330314 0.451399089,19.1816013 0.323076923,19.0846154 C0.121388021,18.9410186 0.00114485547,18.7091211 1.30104261e-16,18.4615385 L1.30104261e-16,3.84615385 C-0.0179934366,3.50142126 0.195868509,3.1869184 0.523076923,3.07692308 L7.44615385,0.769230769 C7.59918535,0.723253743 7.76235311,0.723253743 7.91538462,0.769230769 L15.3846154,3.07692308 L22.0769231,0.846153846 C22.3115946,0.768772483 22.5691533,0.808837169 22.7692308,0.953846154 C22.954515,1.09251101 23.0675229,1.30722611 23.0769257,1.53846154 L23.0769257,16.1538462 C23.0777767,16.4845507 22.8671629,16.7787907 22.5538462,16.8846154 L15.6307692,19.1923077 C15.4777377,19.2382847 15.31457,19.2382847 15.1615385,19.1923077 L7.69230769,16.9615385 L1.01538462,19.2307692 C0.933707551,19.2418517 0.850907834,19.2418517 0.769230769,19.2307692 Z M7.69230769,15.3846154 C7.76627165,15.3737761 7.84142065,15.3737761 7.91538462,15.3846154 L15.3846154,17.6923077 L21.5384615,15.6384615 L21.5384615,2.60769231 L15.6307692,4.61538462 C15.4777377,4.66136164 15.31457,4.66136164 15.1615385,4.61538462 L7.69230769,2.34615385 L1.53846154,4.4 L1.53846154,17.3923077 L7.44615385,15.4230769 C7.52606885,15.3990982 7.60888701,15.3861578 7.69230769,15.3846154 L7.69230769,15.3846154 Z"></path>
                  <path d="M15.3846154,18.4615385 C14.959781,18.4615385 14.6153846,18.1171421 14.6153846,17.6923077 L14.6153846,3.84615385 C14.6153846,3.42131942 14.959781,3.07692308 15.3846154,3.07692308 C15.8094498,3.07692308 16.1538462,3.42131942 16.1538462,3.84615385 L16.1538462,17.6923077 C16.1538462,18.1171421 15.8094498,18.4615385 15.3846154,18.4615385 Z"></path>
                  <path d="M7.69230769,16.1538462 C7.26747327,16.1538462 6.92307692,15.8094498 6.92307692,15.3846154 L6.92307692,1.53846154 C6.92307692,1.11362712 7.26747327,0.769230769 7.69230769,0.769230769 C8.11714212,0.769230769 8.46153846,1.11362712 8.46153846,1.53846154 L8.46153846,15.3846154 C8.46153846,15.8094498 8.11714212,16.1538462 7.69230769,16.1538462 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
