import classNames from "classnames"

export default function Falcon({ className }) {
  const classes = ["fill-current", className]

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13.01 12.983"
      className={classNames(classes)}>
      <path d="M10.35 1.401a6.56 6.56 0 0 0-.518-.35l-.013-.008a2.987 2.987 0 0 0-.554-.247C8.78.64 8.34.656 7.99.846a1.084 1.084 0 0 0-.324.258l-.022.027a6.34 6.34 0 0 1 1.701.659 6.403 6.403 0 0 1 2.747 3.086 6.345 6.345 0 0 1 .422 3.442 6.559 6.559 0 0 1-.02.12 5.16 5.16 0 0 1-.031.17l-.015.068a6.617 6.617 0 0 1-.116.464A6.378 6.378 0 0 0 10.35 1.4m.267 8.755a6.34 6.34 0 0 1-1.42 1.144 6.404 6.404 0 0 1-4.046.837 6.345 6.345 0 0 1-3.192-1.357l-.085-.069-.01-.008a3.817 3.817 0 0 1-.107-.092l-.024-.02-.052-.048a6.306 6.306 0 0 1-.343-.332 6.378 6.378 0 0 0 7.693 2.154 6.521 6.521 0 0 0 .562-.273l.013-.007a2.95 2.95 0 0 0 .49-.357c.38-.34.585-.732.596-1.13a1.09 1.09 0 0 0-.062-.41.406.406 0 0 0-.013-.032M2.163 2.731A6.347 6.347 0 0 1 5.104.408l.102-.039.011-.004.134-.047.03-.01.067-.022a6.284 6.284 0 0 1 .46-.13A6.378 6.378 0 0 0 .195 5.74a6.647 6.647 0 0 0-.045.623v.015c.002.209.022.411.063.603.107.499.342.873.682 1.081a1.092 1.092 0 0 0 .42.157 6.332 6.332 0 0 1-.28-1.802A6.402 6.402 0 0 1 2.162 2.73" />
      <path d="M9.533 6.472c.146-.035.316-.007.464.006.12.01.265-.002.342-.11.071-.1.06-.217.02-.325a.628.628 0 0 0-.086-.166c-.664-.842-1.337-1.214-1.686-1.432-.874-.548-2.06-1.12-3.044-1.439l-.4-.128-.114-.037a.225.225 0 0 1-.013-.003c-.077-.019-.14-.02-.149.037-.009.062.111.143.208.193l.027.107c.526.426 1.457 1.157 1.673 1.443.024.033.046.066.067.1.029.047.026.05-.024.072-.046.02-.091.041-.136.064-.147.071-.352.18-.503.243a.564.564 0 0 1-.416.009 83.36 83.36 0 0 1-.915-.309l-.071-.024-.189-.063-.267-.09-.308-.103-.309-.104-.273-.09-.197-.067-.02-.007a.125.125 0 0 0-.134.024l-.022.019-.007.006-.152.126-.015.012-.078.064a.473.473 0 0 0-.036.036.203.203 0 0 0-.003.252l.01.01.002.004.008.008.003.003.022.019L4.547 6.07l.13.094c.29.212.495.392.507.483.003.023.026.768.026.79v.081a.093.093 0 0 1-.065.068.246.246 0 0 1-.103.015 3.718 3.718 0 0 1-.904-.187 4.613 4.613 0 0 1-.334-.125c-.369-.155-.73-.324-1.1-.477-.115-.047-.23-.096-.344-.145l-.056-.024c-.035-.015-.072-.032-.11-.046l-.002-.001-.038-.015c-.056-.019-.088-.002-.102.04-.14-.09-.013.182.195.407l.398.418a8.094 8.094 0 0 0 3.027 1.996c.32.12.648.223.984.291.514.105.907.156 1.419.052.325-.066.775-.222.72-.637a.3.3 0 0 0-.005-.028c-.032-.15-.143-.159-.233-.278-.076-.1-.13-.347-.152-.469a11.48 11.48 0 0 1 1.272-.134c.071.004.292.093.348.112a.349.349 0 0 0 .037.012v-.16a.656.656 0 0 1 .208.046l.038.018v-.001a.41.41 0 0 0-.096-.384.436.436 0 0 0 0-.078.7.7 0 0 0-.156-.375 2.397 2.397 0 0 0-.296-.274c-.055-.046-.11-.092-.163-.141-.105-.1-.232-.185-.341-.284.033-.132.087-.21.279-.257" />
    </svg>
  )
}
