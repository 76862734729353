import { usePathData } from "@/lib/contexts/appContext"
import classNames from "classnames/bind"
import styles from "./block.module.css"

export default function Block({
  block,
  blockRef,
  className,
  children,
  innerWrapper = false,
  innerHtml = false,
  noStyling = false,
  width = "full",
  gap = "normal",
  style = {},
  dataProps = {},
  id = null
}) {
  const cx = classNames.bind(styles)
  const { template } = usePathData()

  const classes = [
    {
      wrapper: !noStyling,
      "width-bleed": !noStyling && width === "bleed",
      "width-full": !noStyling && width === "full",
      "width-column": !noStyling && width === "column",
      "width-large": template !== "article" && !noStyling && width === "large",
      "width-large-article":
        template === "article" && !noStyling && width === "large",
      "gap-normal": !noStyling && gap === "normal",
      "gap-none": !noStyling && gap === "none",
      "gap-small": !noStyling && gap === "small",
      "gap-content": !noStyling && gap === "content",
      "gap-large": !noStyling && gap === "large"
    },
    className
  ]

  if (noStyling) {
    width = gap = null
  }

  if (innerHtml) {
    return (
      <div
        ref={blockRef}
        className={cx(classes)}
        data-block={block?.name}
        style={style}
        dangerouslySetInnerHTML={{ __html: innerHtml }}
        {...dataProps}
      />
    )
  }

  return (
    <div
      ref={blockRef}
      className={cx(classes)}
      style={style}
      id={id}
      data-block={block?.name}
      {...dataProps}>
      {innerWrapper && <div className="inner-wrapper">{children}</div>}
      {!innerWrapper && children}
    </div>
  )
}
