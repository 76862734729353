import { useRef } from "react"
import useMenuItems from "@/lib/helpers/menu"
import useDeviceDetect from "@/lib/hooks/useDeviceDetect"
import { usePropsObject } from "@/lib/contexts/appContext"
import Link from "@/components/common/menu/link"
import Menu from "@/components/common/menu/menu"
import { Caret } from "@/components/common/icons/caret"
import Footer from "./footer"
import classNames from "classnames/bind"
import styles from "./support.module.css"
import { submenu } from "./menu.module.css"

export default function Support({ setActive, isActive }) {
  const supportUsRef = useRef(null)
  const menuItems = useMenuItems("support")
  const secondaryItems = useMenuItems("support-secondary")
  const cx = classNames.bind(styles)
  const { isMobile } = useDeviceDetect()
  const object = usePropsObject()

  if (!menuItems) {
    return null
  }

  const supportMenu = [...menuItems].shift()
  let { url, label, items } = supportMenu

  const handleClick = () => {
    if (!isMobile) {
      return
    }
    setActive("supportUs", !isActive)
  }

  return (
    <div
      className={cx([styles.support, object?.theme?.accentColor])}
      ref={supportUsRef}
      onMouseEnter={() => !isMobile && setActive("supportUs", true)}
      onMouseLeave={() => !isMobile && setActive("supportUs", false)}
      onClick={() => handleClick()}
      role="support">
      <MainItem
        isMobile={isMobile}
        isActive={isActive}
        label={label}
        url={url}
        items={items}
      />
      {items.length > 0 && (
        <div
          className={cx([
            { submenu: true, on: isActive },
            submenu,
            object?.theme?.accentColor
          ])}>
          <nav role="scroll-wrapper">
            <Menu
              menuClasses="primary"
              items={items}
              location="support"
              caret={true}
            />
            <Menu
              menuClasses="secondary"
              items={secondaryItems}
              location="2nd-support"
            />
            <Footer location="support" />
          </nav>
        </div>
      )}
    </div>
  )
}

function MainItem({ isMobile, isActive, label, url, items }) {
  const cx = classNames.bind(styles)

  if (!isMobile) {
    return (
      <Link
        item={{ label: label, url: url }}
        className={cx({ "main-item": true })}>
        {items.length > 0 && (
          <Caret className={cx({ caret: true, rotate: isActive })} />
        )}
      </Link>
    )
  }

  return (
    <span className={cx({ "main-item": true })}>
      {label}
      {items.length > 0 && (
        <Caret className={cx({ caret: true, rotate: isActive })} />
      )}
    </span>
  )
}
