export default function LogoSpinner({ className = "mb-6 fill-current" }) {
  return (
    <svg
      width="50px"
      height="50px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      className={className}>
      <g fillRule="evenodd">
        <path
          className="origin-center animate-spin"
          d="M39.925 5.405a25.3 25.3 0 00-1.998-1.351l-.05-.03c-.7-.396-1.414-.718-2.135-.95-1.87-.608-3.573-.542-4.924.19a4.182 4.182 0 00-1.249.996c-.029.034-.056.07-.083.104a24.455 24.455 0 016.56 2.54A24.694 24.694 0 0146.64 18.806a24.469 24.469 0 011.626 13.276 25.295 25.295 0 01-.073.46 19.9 19.9 0 01-.124.657l-.055.265a25.52 25.52 0 01-.45 1.788c4.682-10.283 1.772-22.79-7.64-29.847M40.956 39.168a24.449 24.449 0 01-5.478 4.411 24.697 24.697 0 01-15.605 3.227 24.47 24.47 0 01-12.311-5.232c-.11-.087-.219-.176-.327-.264l-.035-.03c-.14-.116-.28-.234-.415-.355l-.092-.08-.202-.182c-.46-.413-.9-.841-1.323-1.283 6.565 9.197 18.85 12.93 29.668 8.308a25.15 25.15 0 002.168-1.054l.05-.028c.694-.408 1.33-.867 1.893-1.374 1.46-1.316 2.254-2.824 2.297-4.36a4.205 4.205 0 00-.239-1.58c-.015-.042-.032-.083-.05-.124M8.35 10.534a24.477 24.477 0 0111.343-8.958c.131-.05.262-.102.394-.15l.042-.016c.171-.063.343-.124.515-.183l.116-.038c.085-.03.172-.057.259-.085A24.236 24.236 0 0122.79.601C11.544 1.687 2.168 10.46.762 22.139a25.636 25.636 0 00-.172 2.405l.001.057c.006.805.085 1.585.243 2.327.41 1.922 1.318 3.364 2.627 4.169a4.21 4.21 0 001.62.602A24.42 24.42 0 014 24.75a24.69 24.69 0 014.35-14.215"
        />
        <path d="M36.774 24.964c.562-.135 1.218-.026 1.79.022.464.039 1.023-.007 1.318-.423.275-.388.233-.839.076-1.257-.086-.227-.186-.46-.328-.64-2.563-3.247-5.158-4.68-6.504-5.524-3.372-2.113-7.944-4.32-11.74-5.547l-1.544-.496-.437-.14a.869.869 0 01-.049-.013c-.3-.074-.545-.078-.575.139-.034.242.427.553.8.747l.103.41c2.03 1.646 5.622 4.464 6.452 5.568.093.125.178.254.26.386.111.18.1.188-.094.275-.176.078-.35.16-.524.246-.567.277-1.355.696-1.938.939-.576.24-1.018.204-1.607.035-.172-.049-1.68-.563-3.526-1.19l-.276-.093-.728-.244-1.03-.346-1.187-.398-1.192-.4-1.052-.352-.76-.255-.077-.025a.482.482 0 00-.517.093c-.029.024-.057.05-.087.072l-.028.022-.586.485-.056.046-.3.248c-.022.02-.044.039-.064.06-.026.025-.05.052-.075.08a.782.782 0 00-.012.97l.034.042.01.01.03.032.01.012c.026.026.054.05.082.074l6.691 4.776c.173.123.342.243.503.36 1.116.819 1.91 1.515 1.954 1.865.01.087.103 2.962.103 3.049v.19c0 .037 0 .073-.003.108l-.001.013c-.027.132-.122.218-.247.263a.948.948 0 01-.398.057c-1.184-.082-2.367-.349-3.488-.72a17.79 17.79 0 01-1.288-.485c-1.422-.596-2.817-1.25-4.243-1.837-.444-.183-.885-.373-1.326-.562l-.216-.092c-.135-.057-.276-.123-.42-.178l-.013-.005-.147-.055c-.213-.075-.339-.007-.394.153-.537-.35-.05.7.754 1.568.012.013 1.079 1.136 1.535 1.613 3.294 3.45 7.216 6.012 11.675 7.697 1.234.467 2.498.86 3.792 1.124 1.984.403 3.5.602 5.473.201 1.256-.256 2.992-.856 2.778-2.459a1.157 1.157 0 00-.018-.105c-.123-.58-.553-.614-.899-1.072-.294-.39-.504-1.342-.587-1.81 1.073-.227 4.485-.538 4.908-.516.274.014 1.125.356 1.34.43.047.017.095.032.142.048v-.616c.264.015.538.064.805.173l.146.07v-.004c.165-.582-.057-1.14-.37-1.48a1.683 1.683 0 00-.003-.302c-.058-.522-.258-1.047-.6-1.446-.337-.394-.744-.726-1.14-1.057-.212-.177-.428-.353-.629-.544-.404-.387-.896-.712-1.317-1.093.127-.51.336-.811 1.076-.99" />
      </g>
    </svg>
  )
}
