export default function Email({ className = "w-6 h-6 fill-current" }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 48 48"
      className={className}>
      <g>
        <polygon points="46.1,5 1.8,5 24,24.6" />
        <polygon points="15.6,23.9 1,11 1,39.4" />
        <polygon points="47,10.9 32.3,23.9 47,39.4" />
        <polygon points="47,43 47,43 47,43" />
        <path d="M28.6,27.2l-2.3,2c-0.7,0.6-1.5,0.9-2.3,0.9c-0.8,0-1.7-0.3-2.3-0.9l-2.3-2L4.4,43h39.1L28.6,27.2z" />
      </g>
    </svg>
  )
}
