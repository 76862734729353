import { useMenus } from "@/lib/contexts/appContext"

export default function useMenuItems(menuLocation = "primary") {
  const menus = useMenus()

  if (!menus?.length) {
    return null
  }

  const menuItems = menus.find((item) => item.location === menuLocation)

  return menuItems?.items && menuItems.items.length > 0 ? menuItems.items : null
}
