import Link from "./link"

export default function Simple({ menuClasses, items, location }) {
  if (!items || items.length === 0) {
    return null
  }

  return (
    <ul className={menuClasses} role={location + "-menu"}>
      {items.map((item, index) => (
        <li key={location + item.url + index}>
          <Link item={item} location={location} />
          {item.items.length > 0 && (
            <ul className="sub-menu">
              {item.items.map((item, index) => (
                <li key={location + item.url + index}>
                  <Link item={item} location={location} />
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  )
}
