export default function Close({ className = "w-3 h-auto text-white" }) {
  return (
    <svg
      viewBox="0 0 12 12"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M11.8334 1.34166L10.6584 0.166656L6.00002 4.82499L1.34169 0.166656L0.166687 1.34166L4.82502 5.99999L0.166687 10.6583L1.34169 11.8333L6.00002 7.17499L10.6584 11.8333L11.8334 10.6583L7.17502 5.99999L11.8334 1.34166Z" />
    </svg>
  )
}
