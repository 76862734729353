import classNames from "classnames/bind"
import styles from "./header.module.css"

export default function Overlay({ active }) {
  const cx = classNames.bind(styles)

  return (
    <div
      className={cx({
        overlay: true,
        "no-print": true,
        "is-active": active
      })}
      role="overlay"></div>
  )
}
