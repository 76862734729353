import Image from "next/legacy/image"
import { useCopy } from "@/lib/contexts/appContext"
import Social from "@/common/social/social"
import styles from "./footer.module.css"

export default function Footer({ location }) {
  const copy = useCopy()
  const { ninetyLogoWhite, ninetyLogoBlack } = copy.general
  const image = location === "primary" ? ninetyLogoWhite : ninetyLogoBlack

  return (
    <>
      <Social styles={styles} location={location} />
      {image && (
        <figure className={styles.ninety}>
          <Image
            className={styles.ninety}
            src={image.url}
            sizes="256px"
            width={image.width}
            height={image.height}
            alt={image.alt}
          />
        </figure>
      )}
    </>
  )
}
