export function UserFilled({ className = "" }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path d="M8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 9C5.33 9 0 10.34 0 13V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V13C16 10.34 10.67 9 8 9Z" />
    </svg>
  )
}

export function UserStroke({ className = "" }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path d="M8 1.9C9.16 1.9 10.1 2.84 10.1 4C10.1 5.16 9.16 6.1 8 6.1C6.84 6.1 5.9 5.16 5.9 4C5.9 2.84 6.84 1.9 8 1.9ZM8 10.9C10.97 10.9 14.1 12.36 14.1 13V14.1H1.9V13C1.9 12.36 5.03 10.9 8 10.9ZM8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 9C5.33 9 0 10.34 0 13V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V13C16 10.34 10.67 9 8 9Z" />
    </svg>
  )
}

export function UserOnline({ className } = "fill-current") {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
      className={className}>
      <path d="M9.943 9.146c-.628-.08-1.25-.21-1.884-.212a15.872 15.872 0 0 0-5.9 1.138c-.633.252-1.282.506-1.682 1.016-.572.725-.472 1.772-.465 2.754.022 1.173 1.07 2.14 2.23 2.146 2.286 0 4.573-.002 6.86 0-.258-.335-.54-.653-.707-1.047a4.955 4.955 0 0 1 0-3.883 5.035 5.035 0 0 1 1.074-1.59c.135-.135.324-.202.474-.321z" />
      <mask id="a">
        <path d="M8.017 0c1.77.016 3.446 1.347 3.784 3.063.421 2.141-1.368 4.476-3.784 4.49-1.845.012-3.59-1.423-3.861-3.225C3.849 2.285 5.534.144 7.744.008 7.835.002 7.926 0 8.017 0Z" />
      </mask>
      <path d="M8.017 0c1.77.016 3.446 1.347 3.784 3.063.421 2.141-1.368 4.476-3.784 4.49-1.845.012-3.59-1.423-3.861-3.225C3.849 2.285 5.534.144 7.744.008 7.835.002 7.926 0 8.017 0Z" />
      <path
        d="m8.017 0 .01-1H8.02l-.004 1Zm3.784 3.063.98-.194-.98.194Zm-3.784 4.49.007 1-.007-1ZM4.156 4.329l-.989.148.989-.148ZM7.744.008l-.061-.999.061.999ZM8.008 1c1.321.012 2.57 1.031 2.812 2.256l1.962-.387C12.347.663 10.244-.979 8.027-1l-.019 2Zm2.812 2.256c.3 1.527-1.021 3.287-2.809 3.298l.013 2c3.043-.02 5.3-2.928 4.758-5.685l-1.963.387ZM8.01 6.554c-1.373.008-2.673-1.089-2.866-2.375l-1.978.297c.349 2.32 2.54 4.092 4.857 4.078l-.013-2ZM5.145 4.179c-.219-1.454 1.027-3.072 2.66-3.173L7.683-.991c-2.786.173-4.91 2.835-4.515 5.467l1.978-.297Zm2.66-3.173c.067-.004.137-.006.21-.006l.006-2c-.11 0-.223.002-.338.01l.123 1.996Z"
        mask="url(#a)"
      />
      <circle
        cx="12.982"
        cy="13.007"
        r="2.97"
        fill="#b9d232"
        fillRule="evenodd"
      />
    </svg>
  )
}
