import fetchAPI from "./fetchAPI"

export async function requestArticleLike(id, action, token) {
  const { articleLike } = await fetchAPI(
    `mutation($id: ID!, $action: LikeAction!, $token: String!) {
        articleLike(id: $id, action: $action, token: $token) {
          status
          likes
        }
      }`,
    {
      variables: { id, action, token }
    },
    true
  )

  return articleLike
}

export async function fetchLikeCount(id) {
  const { fetchLikeCount } = await fetchAPI(
    `query($id: ID!) {
        fetchLikeCount(id: $id) {
          likes
        }
      }`,
    {
      variables: { id }
    }
  )
  return fetchLikeCount
}
