import Main from "@/components/wrappers/main"
import { usePathData } from "@/lib/contexts/appContext"
import Blocks from "@/components/Blocks"
import styles from "./protected.module.css"

export default function Protected() {
  const pathData = usePathData()

  return (
    <Main className={styles.main}>
      <Blocks blocks={pathData.content} />
    </Main>
  )
}
