import useMenuItems from "@/lib/helpers/menu"
import Menu from "@/components/common/menu/simple"
import classNames from "classnames"
import styles from "./menu.module.css"

export default function Tertiary() {
  const location = "tertiary-footer"
  const items = useMenuItems(location)

  return (
    <>
      <h3 className={styles.heading}>Naturskyddsföreningen på din ort</h3>
      <Menu
        menuClasses={classNames([styles.menu, "tertiary"])}
        items={items}
        location={location}
      />
    </>
  )
}
