export default function Remove({ className = "", strokeColor = "#101010" }) {
  return (
    <svg
      viewBox="0 0 8 8"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round">
        <g
          transform="translate(-639.000000, -227.000000)"
          stroke={strokeColor}
          strokeWidth="2">
          <g transform="translate(405.000000, 201.000000)">
            <g transform="translate(228.000000, 20.000000)">
              <g transform="translate(7.000000, 7.000000)">
                <line x1="0" y1="6" x2="6" y2="0" id="Path-2"></line>
                <line
                  x1="0"
                  y1="6"
                  x2="6"
                  y2="0"
                  transform="translate(3.000000, 3.000000) scale(-1, 1) translate(-3.000000, -3.000000) "></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
