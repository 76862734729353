export default function Dashboard({ className = "" }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}>
      <g></g>
      <path d="M3.76 16h12.48q1.76-2.19 1.76-5 0-1.63-0.635-3.11t-1.705-2.55-2.55-1.705-3.11-0.635-3.11 0.635-2.55 1.705-1.705 2.55-0.635 3.11q0 2.81 1.76 5zM10 4q0.41 0 0.705 0.295t0.295 0.705-0.295 0.705-0.705 0.295-0.705-0.295-0.295-0.705 0.295-0.705 0.705-0.295zM6 6q0.41 0 0.705 0.295t0.295 0.705-0.295 0.705-0.705 0.295-0.705-0.295-0.295-0.705 0.295-0.705 0.705-0.295zM14 6q0.41 0 0.705 0.295t0.295 0.705-0.295 0.705-0.705 0.295-0.705-0.295-0.295-0.705 0.295-0.705 0.705-0.295zM8.63 11.55l3.37-4.55v6q0 0.82-0.59 1.41t-1.41 0.59-1.41-0.59-0.59-1.41q0-0.85 0.63-1.45zM4 10q0.41 0 0.705 0.295t0.295 0.705-0.295 0.705-0.705 0.295-0.705-0.295-0.295-0.705 0.295-0.705 0.705-0.295zM16 10q0.41 0 0.705 0.295t0.295 0.705-0.295 0.705-0.705 0.295-0.705-0.295-0.295-0.705 0.295-0.705 0.705-0.295zM11 13q0-0.41-0.295-0.705t-0.705-0.295-0.705 0.295-0.295 0.705 0.295 0.705 0.705 0.295 0.705-0.295 0.295-0.705z"></path>
    </svg>
  )
}
