import Block from "./Block"

export default function Blocks({ blocks, tracking, settings = {} }) {
  return blocks.map((block, index) => {
    // FIXME add drill down generic settings here?
    return (
      <Block
        tracking={tracking}
        block={block}
        key={index}
        settings={settings[block.name]}
      />
    )
  })
}
