import { useAdminBar } from "@/lib/contexts/appContext"
import classNames from "classnames/bind"
import { useEffect, useState } from "react"
import Loading from "@/components/common/loading/logo-with-text"
import Blocks from "@/components/Blocks"
import Main from "@/components/wrappers/main"
import styles from "./custom.module.css"

export default function Custom({ content, loading }) {
  const cx = classNames.bind(styles)
  const adminBar = useAdminBar()
  const defaultClasses = {
    main: true
  }
  const [classes, setClasses] = useState(defaultClasses)

  useEffect(() => {
    setClasses({
      ...defaultClasses,
      "has-admin-bar": adminBar
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  return (
    <Main className={cx(classes)}>
      {loading && (
        <div className="in-progress">
          <Loading />
        </div>
      )}
      {!loading && <Blocks blocks={content} />}
    </Main>
  )
}
