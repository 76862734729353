import useMenuItems from "@/lib/helpers/menu"
import Menu from "@/components/common/menu/simple"
import styles from "./menu.module.css"

export default function Secondary() {
  const location = "secondary-footer"
  const items = useMenuItems(location)

  return (
    <Menu menuClasses={styles.secondary} items={items} location={location} />
  )
}
