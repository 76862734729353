export default function Magnifier({
  className = "w-5 fill-current stroke-current"
}) {
  return (
    <svg
      viewBox="0 0 24 24"
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M20.862 19.812l-5.039-5.04a.464.464 0 00-.332-.136h-.402A8.123 8.123 0 009.124 1 8.123 8.123 0 001 9.125a8.123 8.123 0 0013.636 5.968v.398c0 .125.05.243.137.332l5.039 5.04a.47.47 0 00.664 0l.386-.387a.47.47 0 000-.664zM9.125 15.999A6.87 6.87 0 012.25 9.125 6.87 6.87 0 019.125 2.25a6.87 6.87 0 016.874 6.875 6.87 6.87 0 01-6.874 6.874z" />
    </svg>
  )
}
