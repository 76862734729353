export default function Edit({ className = "" }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}>
      <g></g>
      <path d="M13.89 3.39l2.71 2.72q0.34 0.35 0.335 0.84t-0.305 0.8l-8.010 8.020-5.56 1.16 1.16-5.58q7.6-7.63 7.99-8.030 0.29-0.3 0.815-0.285t0.865 0.355zM11.16 6.18l-5.59 5.61 1.11 1.11 5.54-5.65zM8.19 14.41l5.58-5.6-1.070-1.080-5.59 5.6z"></path>
    </svg>
  )
}
