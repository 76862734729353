import Email from "@/common/icons/email"
import Facebook from "@/common/icons/facebook"
import LinkedIn from "@/common/icons/linkedin"
import Print from "@/common/icons/print"
import Twitter from "@/common/icons/twitter"
import classNames from "classnames"

export default function Share({
  wrapper = "",
  classes = "w-icon h-icon",
  url,
  label = "",
  iconSize = "small",
  hidePrint = false,
  subject = "",
  bodyText = ""
}) {
  if (typeof url === "undefined") {
    return null
  }

  classes = [
    "block bg-black-100/10 hover:bg-black-100/20 rounded-full flex justify-center items-center",
    classes
  ]

  const labelElem =
    label.length === 0 ? (
      ""
    ) : (
      <li className="mb-3 font-sans text-base font-bold tracking-wider text-center uppercase">
        {label}
      </li>
    )

  let icons = {
    small: {
      twitter: "w-[14px]",
      facebook: "w-4",
      linkedin: "w-4",
      email: "w-4 h-4"
    },
    large: {
      twitter: "w-[20px]",
      facebook: "w-6",
      linkedin: "w-5",
      email: "w-5 h-5"
    }
  }

  let services = [
    {
      name: "facebook",
      url: "https://www.facebook.com/share.php?u=",
      icon: <Facebook className={icons[iconSize].facebook} />
    },
    {
      name: "linkedin",
      url: "https://www.linkedin.com/sharing/share-offsite/?url=",
      icon: <LinkedIn className={icons[iconSize].linkedin} />
    },
    {
      name: "twitter",
      url: "https://twitter.com/intent/tweet?url=",
      icon: <Twitter className={icons[iconSize].twitter} />
    },
    {
      name: "email",
      url:
        "mailto:" +
        encodeURI(
          subject
            ? "?subject=" + subject + "&body=" + bodyText
            : "?body=" + bodyText
        ),
      icon: <Email className={icons[iconSize].email} />
    },
    {
      name: "print",
      fn: "print",
      url: null,
      icon: <Print className="w-5 h-4" />
    }
  ]

  const onClick = (name) => {
    if (typeof window.dataLayer === "undefined") {
      return
    }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "share-intent",
      service: name
    })
  }

  services = services.map((service, key) => {
    if (service?.fn === "print") {
      if (hidePrint) {
        return null
      }

      return (
        <li key={key}>
          <a
            onClick={() => {
              window.print()
              onClick("print")
            }}
            className={classNames(classes)}>
            {service.icon}
          </a>
        </li>
      )
    }

    return (
      <li key={key}>
        <a
          className={classNames(classes)}
          href={service.url + encodeURI(url)}
          rel="noreferrer"
          target="_blank"
          onClick={() => onClick(service.name)}>
          {service.icon}
        </a>
      </li>
    )
  })

  return (
    <>
      <ul className={classNames(["sharing", "flex", "no-print", wrapper])}>
        {labelElem}
        {services}
      </ul>
    </>
  )
}
