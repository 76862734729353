import fetchAPI from "./fetchAPI"

export async function newsletterSignup(email, token) {
  const { status } = await fetchAPI(
    `mutation($email: String!, $token: String!) {
        newsletterSignup(email: $email, token: $token) {
          status
        }
      }`,
    {
      variables: { email, token }
    }
  )

  return status
}
