import fetchAPI from "./fetchAPI"

export async function getAutoStartToken(captcha) {
  const { startMemberAuth } = await fetchAPI(
    `query($captcha: String!) {
      startMemberAuth(captcha: $captcha) {
        error
        message
        token
        ref
      }
    }`,
    {
      variables: { captcha }
    },
    true
  )

  return startMemberAuth
}

export async function collectStatus(orderRef, captcha) {
  const { collectStatus } = await fetchAPI(
    `query($orderRef: String!, $captcha: String!) {
      collectStatus(orderRef: $orderRef, captcha: $captcha) {
        status
        message
        token
      }
    }`,
    {
      variables: { orderRef, captcha }
    },
    true
  )
  return collectStatus
}

export async function fetchQrCode(orderRef, captcha) {
  const { fetchQrCode } = await fetchAPI(
    `query($orderRef: String!, $captcha: String!) {
      fetchQrCode(orderRef: $orderRef, captcha: $captcha) {
        error
        message
        image
      }
    }`,
    {
      variables: { orderRef, captcha }
    },
    true
  )

  return fetchQrCode
}

export async function validateMemberToken(token) {
  const { validatedToken } = await fetchAPI(
    `query($token: String!) {
      validateMemberToken(token: $token) {
          valid
        }
      }`,
    {
      variables: { token }
    },
    true
  )

  return validatedToken
}

export async function fetchMemberProps(token) {
  const { fetchMemberProps } = await fetchAPI(
    `query($token: String!) {
      fetchMemberProps(token: $token) {
        status
        firstName
        lastName
        email
        settings
        menu {
          items {
            url
            target
            label
            props
          }
        }
        }
      }`,
    {
      variables: { token }
    },
    true
  )

  return fetchMemberProps
}

export async function updateMemberSettings(token, captcha, data) {
  const { updateMemberSettings } = await fetchAPI(
    `mutation($token: String!, $captcha: String!, $data: JSON!) {
      updateMemberSettings(token: $token, captcha: $captcha, data: $data) {
          status
        }
      }`,
    {
      variables: { token, captcha, data }
    },
    true
  )

  return updateMemberSettings
}

export async function updateMemberContact(token, captcha, data) {
  const { updateMemberContact } = await fetchAPI(
    `mutation($token: String!, $captcha: String!, $data: JSON!) {
      updateMemberContact(token: $token, captcha: $captcha, data: $data) {
          status
        }
      }`,
    {
      variables: { token, captcha, data }
    },
    true
  )

  return updateMemberContact
}

export async function updateMemberNetworks(token, captcha, data) {
  const inputData = { list: [...data] }

  const { updateMemberNetworks } = await fetchAPI(
    `mutation($token: String!, $captcha: String!, $inputData: IntArrayInput!) {
      updateMemberNetworks(token: $token, captcha: $captcha, data: $inputData) {
          status
        }
      }`,
    {
      variables: { token, captcha, inputData }
    },
    true
  )

  return updateMemberNetworks
}

export async function renewMemberToken(token) {
  const { renewMemberToken } = await fetchAPI(
    `mutation($token: String!) {
        renewMemberToken(token: $token) {
            status,
            message,
            token
          }
        }`,
    {
      variables: { token }
    },
    true
  )
  return renewMemberToken
}

export async function setEventFavorite(token, captcha, eventId) {
  const { setEventFavorite } = await fetchAPI(
    `mutation($token: String!, $captcha: String!, $eventId: Int!) {
      setEventFavorite(token: $token, captcha: $captcha, eventId: $eventId) {
          status
        }
      }`,
    {
      variables: { token, captcha, eventId }
    },
    true
  )

  return setEventFavorite
}

export async function catalogOrder(token, captcha, data) {
  const { catalogOrder } = await fetchAPI(
    `mutation($token: String!, $captcha: String!, $data: JSON!) {
      catalogOrder(token: $token, captcha: $captcha, data: $data) {
          status
          message
        }
      }`,
    {
      variables: { token, captcha, data }
    },
    true
  )

  return catalogOrder
}
