import useMenuItems from "@/lib/helpers/menu"
import Menu from "@/components/common/menu/menu"
import styles from "./featured.module.css"
import { usePropsObject } from "@/lib/contexts/appContext"
import classNames from "classnames"

export default function MenuFeatured({ hasMounted, isNarrow, handleOverlay }) {
  const location = "featured"
  const items = useMenuItems(location)
  const object = usePropsObject()

  if (!items) {
    return null
  }

  if (hasMounted && isNarrow) {
    return null
  }

  return (
    <Menu
      menuClasses={classNames([styles.menu, object?.theme?.accentColor])}
      items={items}
      location={location}
      hover={true}
      handleOverlay={handleOverlay}
    />
  )
}
