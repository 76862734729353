export default function ThumbsUp({
  className = "w-5 mb-1 fill-black-100",
  isLiked = false
}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34.25 44.61">
      <path
        d="M41.41,35.47a5.59,5.59,0,0,0,0-5.72A4.09,4.09,0,0,0,41.46,27,4.82,4.82,0,0,0,39,24.22a6.71,6.71,0,0,0-2.86-.57,21.17,21.17,0,0,0-2.43.19l-.53.05a33.74,33.74,0,0,0-3.36.53,8.37,8.37,0,0,1,.67-3.51c.14-.44.29-.82.38-1.2.53-1.83,1.49-5.25-.77-7.46a5,5,0,0,0-3.4-1.35h-.3a2.39,2.39,0,0,0-1.82,1.15A4.3,4.3,0,0,0,24.11,14a1,1,0,0,1-.05.39,13.46,13.46,0,0,1-.39,2,23.36,23.36,0,0,1-1.39,3.8A28.54,28.54,0,0,1,19,25.71a7,7,0,0,1-.77.87,11,11,0,0,0-1.63,2.16,3.9,3.9,0,0,0-1.83-.48H11.65A3.7,3.7,0,0,0,8,32V42.49a3.71,3.71,0,0,0,3.7,3.71h3a3.8,3.8,0,0,0,1.92-.53c.09,0,.14,0,.24.09A72.11,72.11,0,0,0,28.24,47h.13a30,30,0,0,0,6.12-.62c3-.67,4.81-1.92,5.48-3.8a4.31,4.31,0,0,0,.19-1.92v-.1A5.59,5.59,0,0,0,41.41,35.47ZM14.68,44.2h-3A1.71,1.71,0,0,1,10,42.49V32a1.71,1.71,0,0,1,1.7-1.7h3.11a2.05,2.05,0,0,1,.88.23.94.94,0,0,0,.15.06V43.86a1.89,1.89,0,0,0-.21.09A1.77,1.77,0,0,1,14.68,44.2Zm25-9.75a2,2,0,0,0-.22,1.5,3.6,3.6,0,0,1-.8,3.24,2,2,0,0,0-.51,1.33v.1a1.94,1.94,0,0,0,0,.24,2.58,2.58,0,0,1-.09,1c-.16.43-.64,1.76-4,2.51a28.25,28.25,0,0,1-5.72.58h-.13a67.55,67.55,0,0,1-10.45-1.07V30.33a1.84,1.84,0,0,0,.55-.59A8.54,8.54,0,0,1,19.65,28a9,9,0,0,0,1-1.09A30.73,30.73,0,0,0,24.09,21a25.67,25.67,0,0,0,1.51-4.11A15.58,15.58,0,0,0,26,14.76a2.69,2.69,0,0,0,.09-.8,2.25,2.25,0,0,1,.22-.91.41.41,0,0,1,.25-.15h.13a3,3,0,0,1,2,.78c1.31,1.29.81,3.5.24,5.47a.19.19,0,0,1,0,.07c-.05.2-.13.44-.22.72l-.12.33a10.17,10.17,0,0,0-.76,4.42,2,2,0,0,0,.85,1.37,2,2,0,0,0,1.14.36,2.28,2.28,0,0,0,.44-.05,31.5,31.5,0,0,1,3.14-.49l.54-.05.12,0a18.56,18.56,0,0,1,2.08-.16,4.54,4.54,0,0,1,2,.37,2.82,2.82,0,0,1,1.43,1.62,2.07,2.07,0,0,1,0,1.41,2,2,0,0,0,.15,1.73A3.56,3.56,0,0,1,39.69,34.45Z"
        transform="translate(-7.95 -2.36)"
      />
      {isLiked && (
        <>
          <path
            d="M31,8.57a.88.88,0,0,0,.52.13.85.85,0,0,0,.67-.39l1.85-2.89a.86.86,0,1,0-1.44-.93L30.78,7.38A.88.88,0,0,0,31,8.57Z"
            transform="translate(-7.95 -2.36)"
          />
          <path
            d="M33.73,11.85a.84.84,0,0,0,.85.54.86.86,0,0,0,.27-.05L38,11.09A.87.87,0,0,0,38.53,10a.85.85,0,0,0-1.11-.48l-3.2,1.25A.86.86,0,0,0,33.73,11.85Z"
            transform="translate(-7.95 -2.36)"
          />
          <path
            d="M19.84,16.37l-3.2,1.25a.86.86,0,0,0-.49,1.11.84.84,0,0,0,.85.54.75.75,0,0,0,.26-.05L20.46,18A.87.87,0,0,0,21,16.85.85.85,0,0,0,19.84,16.37Z"
            transform="translate(-7.95 -2.36)"
          />
          <path
            d="M35,15.47a.86.86,0,1,0-.43,1.66l3.32.87a.78.78,0,0,0,.26,0,.85.85,0,0,0,.17-1.68Z"
            transform="translate(-7.95 -2.36)"
          />
          <path
            d="M16.33,12.36l3.32.87.27,0a.86.86,0,0,0,.16-1.69l-3.32-.87a.85.85,0,0,0-1,.62A.84.84,0,0,0,16.33,12.36Z"
            transform="translate(-7.95 -2.36)"
          />
          <path
            d="M21.78,8.92a.85.85,0,0,0,.71.32A.82.82,0,0,0,23,9a.86.86,0,0,0,.12-1.21L20.92,5.18a.85.85,0,0,0-1.2-.12.86.86,0,0,0-.12,1.21Z"
            transform="translate(-7.95 -2.36)"
          />
          <path
            d="M26.94,7.5a.86.86,0,0,0,.8-.91l-.2-3.43a.85.85,0,0,0-.91-.8.84.84,0,0,0-.8.9L26,6.69A.86.86,0,0,0,26.94,7.5Z"
            transform="translate(-7.95 -2.36)"
          />
        </>
      )}
    </svg>
  )
}
