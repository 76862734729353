import Blocks from "@/components/Blocks"
import Block from "@/components/wrappers/block"
import styles from "./template.module.css"

export default function Template({ content }) {
  if (content.length === 0) {
    return null
  }

  return (
    <Block width="bleed" gap="none" className={styles.template}>
      <Blocks blocks={content} />
    </Block>
  )
}
