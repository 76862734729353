import { useEffect, useState } from "react"
import { usePathData, useAdminBar } from "@/lib/contexts/appContext"
import Main from "@/components/wrappers/main"
import Blocks from "@/components/Blocks"
import styles from "./event.module.css"
import classNames from "classnames/bind"

export default function Event() {
  const cx = classNames.bind(styles)
  const pathData = usePathData()
  const adminBar = useAdminBar()
  const [classes, setClasses] = useState({
    main: true
  })

  useEffect(() => {
    setClasses({
      ...classes,
      "has-admin-bar": adminBar
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData])

  return (
    <Main className={cx(classes)}>
      <Blocks blocks={pathData.content} />
    </Main>
  )
}
