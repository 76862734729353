import classNames from "classnames"
import styles from "./spinner.module.css"

export function Spinner({
  size = "default",
  sizeClasses = "",
  className = "",
  borderClass = "border-black-25",
  spinColor = "rgba(115,175,60,1)"
}) {
  return (
    <div
      className={classNames(
        styles.spinner,
        styles[size],
        sizeClasses,
        borderClass,
        className
      )}
      style={{ borderTopColor: spinColor }}
    />
  )
}
