import { useRef } from "react"
import { usePathData, usePropsObject } from "@/lib/contexts/appContext"
import useOnClickOutside from "@/lib/hooks/useOnClickOutside"
import useMenuItems from "@/lib/helpers/menu"
import Menu from "@/components/common/menu/menu"
import Close from "@/components/common/icons/close"
import Burger from "@/components/common/icons/menu"
import Footer from "./footer"
import classNames from "classnames/bind"
import styles from "./primary.module.css"
import { submenu } from "./menu.module.css"

export default function Primary({
  isActive,
  setActive,
  isMobile,
  isNarrow,
  hasMounted
}) {
  const offCanvasRef = useRef(null)
  const cx = classNames.bind(styles)
  const secondaryItems = useMenuItems("primary-secondary")

  const { sidebarContent } = usePropsObject()
  const pathData = usePathData()
  const hasSidebar = pathData.template === "campaign" && Boolean(sidebarContent)

  useOnClickOutside(offCanvasRef, () => setActive("offCanvas", false), isActive)

  const items = [].concat(
    useMenuItems("featured") ?? [],
    useMenuItems("top") ?? []
  )

  if (items.length === 0) {
    return null
  }

  if (hasMounted && !isNarrow) {
    return null
  }

  return (
    <div className={styles.primary} ref={offCanvasRef} role="primary">
      <button
        type="button"
        className="toggle"
        onClick={() => setActive("offCanvas", !isActive)}>
        {isActive ? <Close className="close" /> : <Burger className="burger" />}
      </button>
      {items.length > 0 && (
        <div
          className={cx(
            {
              submenu: true,
              on: isActive,
              "with-sidebar": hasMounted && !isMobile && isNarrow && hasSidebar
            },
            submenu
          )}>
          {isActive && (
            <nav role="scroll-wrapper">
              <Menu menuClasses="primary" items={items} location="primary" />
              <Menu
                menuClasses="secondary"
                items={secondaryItems}
                location="2nd-primary"
              />
              <Footer location="primary" />
            </nav>
          )}
        </div>
      )}
    </div>
  )
}
