import { blockMapping } from "@/lib/blockMapping"
import { usePropsObject } from "@/lib/contexts/appContext"
import NotFound from "./base/notFound"

export default function Block({ block, tracking, settings }) {
  const object = usePropsObject()
  if (!block.attrs) {
    return null
  }

  let product = tracking

  if (!product) {
    // Get product data from view_item
    const { tracking: tracking2 } = object || {}
    const { view_item } = tracking2 || {}
    const [first] = view_item || []
    product = first
  }

  const BlockComponent = blockMapping[block.name] ?? NotFound

  return <BlockComponent tracking={product} block={block} settings={settings} />
}
