import LogoSpinner from "./logo-spinner"

export default function Loading() {
  return (
    <div className="flex flex-col items-center justify-center h-full font-serif text-3xl">
      <LogoSpinner />
      <div className="flex flex-row">
        Laddar
        <span className="animate-dot" style={{ animationDelay: "600ms" }}>
          .
        </span>
        <span className="animate-dot" style={{ animationDelay: "300ms" }}>
          .
        </span>
        <span className="animate-dot">.</span>
      </div>
    </div>
  )
}
