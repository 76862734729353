export default function Cart({ className = "fill-current" }) {
  return (
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      <path
        d="M15.968 17.777a.997.997 0 11.001-1.995.997.997 0 01-.001 1.995m-7.611 0a.997.997 0 110-1.995.997.997 0 010 1.995M6.147 4.74h11.374L16.487 9.7l-9.23.662c-.19-.961-.665-3.396-1.11-5.621m9.82 8.819a3.215 3.215 0 00-3.01 2.109h-1.59a3.214 3.214 0 00-3.01-2.11 3.216 3.216 0 00-3.01 2.11h-.653c-.804 0-1.46-.655-1.46-1.46 0-.806.656-1.46 1.46-1.46l12.787-.893a1.11 1.11 0 001.01-.88l1.485-7.12a1.108 1.108 0 00-1.087-1.337H5.631C4.707-.071 2.542-.021 1.433.006l-.322.007a1.11 1.11 0 100 2.222l.382-.006c1.164-.033 1.874-.05 2.21 1.62.467 2.319 1.06 5.337 1.32 6.673l-.055.004h-.274a3.687 3.687 0 00-3.682 3.683 3.687 3.687 0 003.682 3.682h.653a3.216 3.216 0 003.01 2.108 3.214 3.214 0 003.01-2.108h1.59a3.215 3.215 0 003.01 2.108 3.222 3.222 0 003.22-3.22 3.223 3.223 0 00-3.22-3.22"
        fillRule="evenodd"
      />
    </svg>
  )
}
