import { useEffect } from "react"

function useOnClickOutside(ref, callback, dependency) {
  const handleClickOutside = (event) => {
    if (!ref?.current || ref.current.contains(event.target)) {
      return
    }

    callback()
  }

  useEffect(() => {
    if (dependency) {
      document.addEventListener("mousedown", handleClickOutside)
      document.addEventListener("touchstart", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
      document.removeEventListener("touchstart", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
      document.removeEventListener("touchstart", handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependency])
}

export default useOnClickOutside
