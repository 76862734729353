import Link from "next/link"
import { useRouter } from "next/router"
import { useMemo, useEffect, useState } from "react"
import { useMediaPredicate } from "react-media-hook"
import { useAppContext, useMeta } from "@/lib/contexts/appContext"
import { useFirstBlock } from "@/lib/helpers/template"
import Falcon from "@/components/common/logos/falcon"
import Text from "@/components/common/logos/text"
import MemberMenuItem from "@/components/base/member/menuItem"
import Overlay from "./overlay"
import AdminBar from "./admin-bar"
import MenuTop from "./menus/top"
import Featured from "./menus/featured"
import Support from "./menus/support"
import Primary from "./menus/primary"
import { Search } from "./search"
import Notifications from "@/components/woocommerce/Notifications"
import ToggleCart from "@/components/woocommerce/ToggleCart"
import classNames from "classnames"
import styles from "./header.module.css"
import MemberStatusCheck from "../member/statusCheck"
import { Notice } from "./notice"

export default function Header({ hasSidebar }) {
  const defaultState = useMemo(() => {
    return {
      search: false,
      supportUs: false,
      memberMenu: false,
      offCanvas: false,
      overlay: false,
      cart: false
    }
  }, [])
  const meta = useMeta()
  const { object } = useAppContext()
  const firstBlock = useFirstBlock()
  const isMobile = useMediaPredicate("(max-width: 768px)") // changed from 640 until we can move cart
  const isNarrow = useMediaPredicate("(max-width: 1024px)")
  const [hasMounted, setMounted] = useState(false)
  const [menuColor, setMenuColor] = useState(
    "text-black-100 border-black-100/10 bg-white"
  )

  // We want to force the narrow menu when page has sidebar
  const narrowMenu = isNarrow || (!isNarrow && hasSidebar)

  useEffect(() => {
    setMenuColor(getMenuColor(firstBlock, object, meta))
  }, [firstBlock, object, meta])

  // This is used due to isMobile not working before mount
  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setMounted(true)
    }
    return () => {
      isMounted = false
    }
  }, [])

  const [state, setState] = useState(defaultState)
  const [scrollTop, setScrollTop] = useState(true)
  const router = useRouter()

  const handleClick = (location, open) => {
    setState({
      ...defaultState,
      overlay: open,
      [location]: open
    })
  }

  const handleScroll = () => {
    if (window.scrollY > 10) {
      setScrollTop(false)
    }

    if (window.scrollY < 10) {
      setScrollTop(true)
    }
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27 && state.overlay) {
        setState({ ...defaultState })
      }
    }

    window.addEventListener("keydown", handleKeyDown)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [state.overlay, defaultState])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {
      passive: true
    })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    const handleRouteChange = () => setState({ ...defaultState })

    router.events.on("routeChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [router.events, defaultState])

  const classes = [
    styles.header,
    !scrollTop ? styles.opaque : menuColor,
    { "has-sidebar": hasSidebar }
  ]

  return (
    <>
      <header id="header" className={classNames(classes)}>
        <AdminBar />
        <MenuTop
          hasMounted={hasMounted}
          isNarrow={narrowMenu}
          isMobile={isMobile}
          handleOverlay={handleClick}
        />
        <div className="menu-bar">
          <Logo hasMounted={hasMounted} isMobile={isMobile} />
          <Featured
            isNarrow={narrowMenu}
            hasMounted={hasMounted}
            handleOverlay={handleClick}
          />
          <Search
            isActive={state.search}
            isNarrow={isNarrow && hasMounted}
            setActive={handleClick}
          />
          {hasMounted && (
            <ToggleCart isActive={state.cart} setActive={handleClick} />
          )}
          {hasMounted && isNarrow && (
            <MemberMenuItem
              context="header"
              setActive={handleClick}
              isActive={state.memberMenu}
            />
          )}
          <Support setActive={handleClick} isActive={state.supportUs} />
          <Primary
            hasMounted={hasMounted}
            isNarrow={narrowMenu}
            isMobile={isMobile}
            isActive={state.offCanvas}
            setActive={handleClick}
          />
        </div>
        <Notice />
      </header>
      <Overlay active={state.overlay} />
      <Notifications />
      <MemberStatusCheck />
    </>
  )
}

export function getMenuColor(firstBlock, object, meta) {
  const hasSearchPanel = object.searchPanel

  let textColor = "text-black-100"
  let borderColor = "border-black-100/10"
  let bgColor = "bg-white"

  if (hasWhiteHeader(firstBlock)) {
    textColor = "text-white"
    borderColor = "border-white"
  }

  if (hasTransparentBg(firstBlock)) {
    bgColor = "bg-transparent"
  }

  if (hasSearchPanel) {
    textColor = "text-black-100"
    borderColor = "border-black-25"
    bgColor = "bg-white"
  }

  if (meta?.hasBankID) {
    textColor = "text-black-100"
    borderColor = "border-black-25"
    bgColor = "bg-white"
  }

  return [textColor, borderColor, bgColor].join(" ")
}

const Logo = ({ hasMounted, isMobile }) => {
  if (!hasMounted) {
    return null
  }

  return (
    <Link href="/" prefetch={false} legacyBehavior>
      <a className={styles.logo}>
        <Falcon className="falcon" />
        {!isMobile && <Text className="text" />}
      </a>
    </Link>
  )
}

export function hasWhiteHeader(firstBlock) {
  if (!firstBlock) {
    return false
  }

  switch (firstBlock.name) {
    case "theme/hero":
      if (
        firstBlock.attrs.layout === "fullwidth" &&
        (firstBlock.attrs.image.tall ||
          firstBlock.attrs.image.wide ||
          firstBlock.attrs.mediaType === "video")
      ) {
        return true
      }
      break

    case "theme/calendar-header":
      if (firstBlock.attrs.image) {
        return true
      }
      break

    default:
      return false
  }

  return false
}

export function hasTransparentBg(firstBlock) {
  if (
    firstBlock?.name === "theme/personnel-header" ||
    firstBlock?.name === "theme/archive-header" ||
    firstBlock?.name === "theme/calendar-header" ||
    firstBlock?.name === "theme/search-header" ||
    firstBlock?.name === "theme/hero"
  ) {
    return true
  }
  return false
}
