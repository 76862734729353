export default function WordPress({ className = "" }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}>
      <g></g>
      <path d="M20 10q0-1.63-0.505-3.155t-1.43-2.755-2.155-2.155-2.755-1.43-3.155-0.505-3.155 0.505-2.755 1.43-2.155 2.155-1.43 2.755-0.505 3.155 0.505 3.155 1.43 2.755 2.155 2.155 2.755 1.43 3.155 0.505 3.155-0.505 2.755-1.43 2.155-2.155 1.43-2.755 0.505-3.155zM10 1.010q1.83 0 3.495 0.71t2.87 1.915 1.915 2.87 0.71 3.495-0.71 3.495-1.915 2.87-2.87 1.915-3.495 0.71-3.495-0.71-2.87-1.915-1.915-2.87-0.71-3.495 0.71-3.495 1.915-2.87 2.87-1.915 3.495-0.71zM8.010 14.82l-3.050-8.21 1.050-0.080q0.2-0.020 0.27-0.275t-0.025-0.49-0.305-0.225q-1.29 0.1-2.13 0.1-0.33 0-0.52-0.010 1.1-1.66 2.87-2.63t3.83-0.97q1.54 0 2.935 0.55t2.475 1.54q-0.52-0.070-0.985 0.305t-0.465 1.115q0 0.29 0.115 0.615t0.225 0.525 0.37 0.61q0.050 0.080 0.080 0.13 0.5 0.87 0.5 2.21 0 0.6-0.315 1.72t-0.635 1.94l-0.32 0.82-2.71-7.5q0.21-0.010 0.4-0.050t0.27-0.080l0.080-0.030q0.2-0.020 0.275-0.295t-0.025-0.535-0.3-0.25q-1.3 0.11-2.14 0.11-0.35 0-0.875-0.030l-0.875-0.050-0.36-0.030q-0.2-0.010-0.3 0.255t-0.025 0.54 0.275 0.285l0.84 0.080 1.12 3.040zM14.030 16.97l2.61-6.97q0.030-0.070 0.070-0.195t0.15-0.535 0.155-0.82 0.080-1.050-0.065-1.21q0.94 1.7 0.94 3.81 0 2.19-1.065 4.050t-2.875 2.92zM2.68 6.77l3.82 10.48q-2.020-0.99-3.245-2.945t-1.225-4.305q0-1.79 0.65-3.23zM10.13 11.3l2.29 6.25q-1.17 0.42-2.42 0.42-1.030 0-2.060-0.3z"></path>
    </svg>
  )
}
