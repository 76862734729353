import Blocks from "@/components/Blocks"
import { NameCollectionProvider } from "@/lib/contexts/nameCollectionContext"
import Block from "@/components/wrappers/block"
import styles from "./name-collection.module.css"
import ProgressBar from "@/components/blocks/theme/name-collection/progress-bar"
import classNames from "classnames"

export default function NameCollection({ block, settings = {} }) {
  const { innerBlocks, attrs } = block
  if (innerBlocks.length === 0) {
    return null
  }

  let containerClasses = ["grid"]
  attrs.layout === "narrow-centered" || block.attrs?.inSidebar
    ? containerClasses.push("mx-auto max-w-1xl md:px-0")
    : containerClasses.push("lg:grid-cols-2 lg:gap-x-10 xl:gap-x-17")

  innerBlocks[0].name === "theme/gravityForm" &&
    containerClasses.push("collection-row-start")

  if (attrs.align === "centered") {
    containerClasses.push("align-info-center")
  }

  const width = block.attrs?.inSidebar ? "bleed" : "full"

  if (settings?.onlyProgress) {
    let progressBlock = null
    const collectionInfo = innerBlocks
      .filter((block) => block.name === "theme/collection-info")
      .shift()
    if (collectionInfo?.name) {
      progressBlock = collectionInfo.innerBlocks
        .filter((block) => block.name === "theme/progress-bar")
        .shift()
    }

    return (
      <NameCollectionProvider formId={attrs.formId} startAt={attrs.startAt}>
        <ProgressBar block={progressBlock} settings={{ isFloating: true }} />
      </NameCollectionProvider>
    )
  }

  settings["theme/gravity-form"] = { width: "bleed" }

  return (
    <Block
      width={width}
      block={block}
      id={block.attrs?.id}
      className={styles.nameCollection}>
      <NameCollectionProvider formId={attrs.formId} startAt={attrs.startAt}>
        <div className={classNames(containerClasses)}>
          <Blocks blocks={innerBlocks} settings={settings} />
        </div>
      </NameCollectionProvider>
    </Block>
  )
}
