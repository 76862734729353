const nameSpace = "nature"

export default class Storage {
  static get(key, defaultValue, method) {
    "use strict"

    var storage = this.getStorage(method)
    var value = storage.getItem(`${nameSpace}.${key}`)

    if (undefined === value || null === value || "undefined" === value) {
      value = defaultValue
    } else {
      value = JSON.parse(value)
    }

    return value
  }

  static set(key, value, method) {
    "use strict"

    var storage = this.getStorage(method)

    return storage.setItem(`${nameSpace}.${key}`, JSON.stringify(value))
  }

  static remove(key, method) {
    "use strict"

    var storage = this.getStorage(method)
    storage.removeItem(`${nameSpace}.${key}`)
  }

  static getStorage(method) {
    "use strict"

    if (undefined === method) {
      method = "local"
    }

    switch (method) {
      case "local":
        return window.localStorage
      case "session":
        return window.sessionStorage
    }
  }
}
