import { useRef, useEffect, useState } from "react"
import SearchForm from "@/common/search-form/search-form"
import useScrollDirection from "@/lib/hooks/useScrollDirection"
import { usePathData, useAdminBar } from "@/lib/contexts/appContext"
import { useArchiveContext } from "@/lib/contexts/archiveContext"
import classNames from "classnames/bind"
import styles from "./search-panel.module.css"

export default function SearchPanel() {
  const scrollDirection = useScrollDirection()
  const cx = classNames.bind(styles)
  const store = useArchiveContext()
  const panel = useRef()
  const pathData = usePathData()
  const adminBar = useAdminBar()
  const [classes, setClasses] = useState({
    wrapper: true,
    hidden: scrollDirection === "down",
    "no-print": true,
    "has-choices": store.selected.length > 0
  })

  useEffect(() => {
    setClasses({
      ...classes,
      "has-admin-bar": adminBar
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData])

  return (
    <div className={cx(classes)} ref={panel}>
      <div className="inner-wrapper">
        <SearchForm data={store.object.search} autoload={false} />
      </div>
    </div>
  )
}
