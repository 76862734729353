export default async function fetchAPI(
  query,
  { variables } = {},
  headers = {}
) {
  headers = { ...headers, "Content-Type": "application/json" }
  const apiURL = process.env.NEXT_PUBLIC_API_URL ?? process.env.API_URL

  try {
    const res = await fetch(apiURL, {
      method: "POST",
      headers,
      body: JSON.stringify({
        query,
        variables
      })
    })

    const json = await res.json()

    if (json.errors) {
      console.error({ query: query, errors: json.errors })
      return json.errors
    }

    return json.data
  } catch (error) {
    console.log("error", error)
    return error
  }
}
