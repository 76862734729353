import React, { createContext, useContext, useReducer } from "react"

export const ArchiveContext = createContext()
export const useArchiveContext = () => useContext(ArchiveContext)

export const ArchiveProvider = ({
  children,
  object,
  list = [],
  phrase = "",
  inputValue = "",
  selected = [],
  sortBy = "",
  page = 1,
  maxPage = 0,
  totalCount = 0,
  context = null,
  cardType = "article"
}) => {
  let loading = !list.length // i.e if we have a list, we dont need to set initil loading to true
  let firstRequest = !!list.length

  if (context === "site") {
    loading = false
  }

  if (context === "calendar") {
    loading = true
  }

  const defaultState = {
    list: list,
    loading: loading, // this should be false if we have initialLoad
    openFilters: [],
    page: page,
    maxPage: maxPage,
    totalCount: totalCount,
    cardType: cardType,
    inputValue: inputValue,
    phrase: phrase,
    selected: selected,
    sortBy: sortBy,
    context: context,
    firstRequest: firstRequest,
    view: "grid"
  }

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "SET_PROGRESS": {
        return {
          ...state,
          loading: action.progress
        }
      }
      case "SET_PAGE": {
        return {
          ...state,
          page: action.page
        }
      }
      case "SET_LIST": {
        return {
          ...state,
          list: action.list,
          totalCount: action.totalCount,
          maxPage: action.maxPage,
          cardType: action.cardType,
          hash: action.hash
        }
      }
      case "SET_PHRASE": {
        return {
          ...state,
          phrase: action.phrase
        }
      }
      case "SET_FULL_QUERY": {
        return {
          ...state,
          phrase: action.phrase,
          selected: action.selected,
          sortBy: action.sortBy,
          page: action.page
        }
      }
      case "SET_INPUT": {
        return {
          ...state,
          inputValue: action.inputValue
        }
      }
      case "SET_SELECTED": {
        return {
          ...state,
          selected: action.selected
        }
      }
      case "SET_SORTBY": {
        return {
          ...state,
          sortBy: action.sortBy
        }
      }
      case "OPEN_FILTERS": {
        return {
          ...state,
          openFilters: action.list
        }
      }
      case "SET_FIRST_REQUEST": {
        return {
          ...state,
          firstRequest: action.firstRequest
        }
      }
      case "SET_VIEW": {
        return {
          ...state,
          view: action.view
        }
      }
      default:
        return state
    }
  }, defaultState)

  const setList = (list, cardType, totalCount, maxPage, hash) =>
    dispatch({ type: "SET_LIST", list, cardType, totalCount, maxPage, hash })
  const setProgress = (progress) =>
    dispatch({ type: "SET_PROGRESS", progress: progress })
  const setFullQuery = (phrase, selected, sortBy, page) =>
    dispatch({
      type: "SET_FULL_QUERY",
      phrase,
      selected,
      sortBy,
      page
    })
  const setOpenFilters = (list) => dispatch({ type: "OPEN_FILTERS", list })
  const setPage = (page) => dispatch({ type: "SET_PAGE", page })
  const setPhrase = (phrase) => dispatch({ type: "SET_PHRASE", phrase })
  const setSelected = (selected) => dispatch({ type: "SET_SELECTED", selected })
  const setSortBy = (sortBy) => dispatch({ type: "SET_SORTBY", sortBy })
  const setInputValue = (inputValue) =>
    dispatch({ type: "SET_INPUT", inputValue })
  const setFirstRequest = (firstRequest) =>
    dispatch({ type: "SET_FIRST_REQUEST", firstRequest })
  const setView = (view) => dispatch({ type: "SET_VIEW", view })

  const getPage = () => {
    return parseInt(state.page, 10)
  }

  const getSortBy = () => {
    return state.sortBy
  }

  const getView = () => {
    return state.view
  }

  return (
    <ArchiveContext.Provider
      value={{
        list: state.list,
        loading: state.loading,
        openFilters: state.openFilters,
        object: object,
        page: state.page,
        maxPage: state.maxPage,
        totalCount: state.totalCount,
        cardType: state.cardType,
        phrase: state.phrase,
        inputValue: state.inputValue,
        selected: state.selected,
        sortBy: state.sortBy,
        context: context,
        firstRequest: state.firstRequest,
        hash: state.hash,
        view: state.view,
        getPage,
        getView,
        setPage,
        setList,
        setProgress,
        setOpenFilters,
        setPhrase,
        setSelected,
        getSortBy,
        setSortBy,
        setInputValue,
        setFirstRequest,
        setFullQuery,
        setView
      }}>
      {children}
    </ArchiveContext.Provider>
  )
}
