import Link from "next/link"
import styles from "./terms.module.css"
import classNames from "classnames"

export default function Terms({ terms, className = "", omitPrimary = false }) {
  terms = omitPrimary ? terms.filter((term) => !term.isPrimary) : terms

  const items = terms.map((term, key) => {
    let link = (
      <Link href={term.url} prefetch={false} legacyBehavior>
        <a className="term">{term.label}</a>
      </Link>
    )

    if (!term.url) {
      link = <a className="term">{term.label}</a>
    }

    return <li key={"article-terms-" + key}>{link}</li>
  })

  if (items.length === 0) {
    return null
  }

  return (
    <ul
      className={classNames(styles.terms, "post-terms", "no-print", className)}>
      {items}
    </ul>
  )
}
