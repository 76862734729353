import Item from "./item"
import classNames from "classnames"

export default function Menu({
  menuClasses,
  items,
  location,
  caret = false,
  hover = false,
  handleOverlay
}) {
  if (!items || items.length === 0) {
    return null
  }

  return (
    <ul
      className={classNames([menuClasses, { "has-caret": caret }])}
      role={location + "-menu"}
      onMouseEnter={() => hover && handleOverlay(location, true)}
      onMouseLeave={() => hover && handleOverlay(location, false)}>
      {items.map((item, index) => (
        <Item
          caret={caret}
          hover={hover}
          key={location + item.url + index}
          location={location}
          item={item}
        />
      ))}
    </ul>
  )
}
