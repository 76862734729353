import classNames from "classnames"

export default function Text({ className }) {
  const classes = ["fill-current", className]

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 238 23"
      className={classNames(classes)}>
      <path
        fillRule="evenodd"
        d="M10.114 12.731 2.832 1.13H0v16.289h2.832V5.862l7.26
      11.557h2.831V1.13h-2.809v11.601m12.426 1.285a2.597 2.597 0 0 1-1.069 1.053
      3.08 3.08 0 0 1-1.516.392c-.582
      0-1.046-.154-1.392-.466-.347-.308-.521-.724-.521-1.247
      0-.64.253-1.136.76-1.486.508-.352 1.265-.526
      2.271-.526h1.467v2.28Zm2.718-4.853c-.022-1.29-.455-2.292-1.298-3.004-.843-.713-1.973-1.069-3.39-1.069-.932
      0-1.777.165-2.534.494-.757.327-1.356.78-1.795 1.357-.44.58-.66 1.2-.66
      1.864H18.3c0-.478.197-.871.592-1.18.396-.31.902-.465 1.522-.465.716 0
      1.249.188 1.6.565.35.376.526.878.526 1.505v.794h-1.667c-1.783
      0-3.153.345-4.112 1.034-.958.69-1.438 1.682-1.438 2.971 0 1.021.385 1.88
      1.153 2.573.768.694 1.76 1.041 2.976 1.041 1.253 0 2.312-.451
      3.177-1.353.09.521.194.898.313
      1.13h2.775v-.19c-.306-.65-.459-1.52-.459-2.608v-5.46Zm6.627
      5.923c-.184-.204-.275-.538-.275-1V7.327h2.137V5.315H31.61V2.372h-2.718v2.943H26.9v2.013h1.992v6.857c0
      2.306 1.044 3.458 3.133 3.458.574 0 1.17-.087 1.79-.258v-2.103a4.17 4.17 0
      0 1-.952.112c-.47 0-.796-.103-.978-.308m11.575-1.079c-.463.948-1.354
      1.42-2.674 1.42-1.276 0-1.914-.766-1.914-2.305V5.315h-2.718v7.842c0
      1.454.337 2.566 1.013 3.334.674.768 1.669 1.152 2.981 1.152 1.47 0
      2.603-.47 3.4-1.41l.068
      1.186h2.562V5.315H43.46v8.692m8.144-7.339-.066-1.353h-2.596v12.104h2.718V9.14c.433-.954
      1.271-1.43 2.518-1.43.38 0 .75.029
      1.107.088l.012-2.552c-.254-.104-.575-.156-.963-.156-1.164 0-2.073.526-2.73
      1.578m12.865
      4.492c-.608-.326-1.445-.605-2.511-.84-1.067-.235-1.776-.467-2.126-.698a1.101
      1.101 0 0 1-.526-.974c0-.462.189-.833.565-1.107.376-.277.863-.415
      1.46-.415.641 0 1.156.174 1.543.52.389.347.583.782.583
      1.304h2.718c0-1.141-.446-2.07-1.336-2.785-.892-.716-2.061-1.075-3.508-1.075-1.357
      0-2.477.35-3.356 1.051-.88.702-1.32 1.575-1.32 2.618 0 1.246.678 2.197
      2.035 2.854.62.297 1.392.545 2.316.738.926.194 1.588.417
      1.987.672.399.253.598.621.598 1.107 0 .44-.187.795-.56
      1.068-.373.273-.917.408-1.632.408-.74
      0-1.332-.173-1.78-.52-.448-.346-.69-.84-.727-1.482h-2.64c0 .724.216
      1.4.649 2.026.432.625 1.037 1.119 1.812 1.476.776.358 1.66.537 2.651.537
      1.462 0 2.644-.338 3.547-1.012.902-.675 1.353-1.557 1.353-2.647
      0-.649-.147-1.2-.442-1.655-.294-.455-.745-.845-1.353-1.169m14.255-5.845h-3.269l-3.345
      3.77-.852 1.063V.236h-2.719v17.183h2.719V13.47l1.209-1.242 3.594
      5.191h3.144l-4.98-7.059 4.499-5.045m6.402 8.21-2.563-8.21h-2.93l4.25
      12.04-.39 1.04c-.203.588-.475 1.007-.822
      1.256-.348.25-.864.375-1.55.375l-.516-.034v2.114a4.84 4.84 0 0 0
      1.31.202c1.768 0 3.021-1.018 3.759-3.054l4.81-13.94h-2.897l-2.46
      8.211m13.962.415c-.515.991-1.327 1.487-2.439 1.487-.873
      0-1.547-.344-2.021-1.034-.472-.69-.709-1.65-.709-2.88
      0-1.404.238-2.453.717-3.151.476-.697 1.155-1.045 2.036-1.045 1.11 0
      1.916.486 2.416
      1.454v5.169Zm0-7.506c-.79-.896-1.827-1.344-3.11-1.344-1.454
      0-2.616.565-3.479 1.696-.866 1.13-1.298 2.627-1.298 4.49 0 1.962.438 3.514
      1.314 4.655.877 1.142 2.023 1.712 3.44 1.712 1.358 0 2.442-.492
      3.255-1.477l.134 1.253h2.462V.236H99.09v6.198Zm13.031
      7.506c-.515.991-1.328 1.487-2.439 1.487-.872
      0-1.546-.344-2.019-1.034-.475-.69-.71-1.65-.71-2.88
      0-1.404.239-2.453.716-3.151.476-.697 1.156-1.045 2.036-1.045 1.112 0
      1.917.486 2.416
      1.454v5.169Zm0-7.506c-.791-.896-1.827-1.344-3.11-1.344-1.454
      0-2.614.565-3.48 1.696-.865 1.13-1.299 2.627-1.299 4.49 0 1.962.44 3.514
      1.316 4.655.876 1.142 2.023 1.712 3.44 1.712 1.358 0 2.442-.492
      3.255-1.477l.135 1.253h2.461V.236h-2.718v6.198Zm13.12
      4.726c-.608-.326-1.446-.605-2.51-.84-1.067-.235-1.777-.467-2.127-.698a1.103
      1.103 0 0 1-.526-.974c0-.462.189-.833.564-1.107.376-.277.863-.415
      1.46-.415.642 0 1.156.174 1.545.52.388.347.583.782.583
      1.304h2.716c0-1.141-.445-2.07-1.337-2.785-.89-.716-2.06-1.075-3.506-1.075-1.357
      0-2.477.35-3.356 1.051-.88.702-1.32 1.575-1.32 2.618 0 1.246.678 2.197
      2.035 2.854.62.297 1.391.545 2.316.738.925.194 1.588.417
      1.986.672.4.253.598.621.598 1.107 0 .44-.186.795-.56
      1.068-.372.273-.916.408-1.632.408-.738
      0-1.332-.173-1.778-.52-.448-.346-.69-.84-.728-1.482h-2.64c0 .724.215
      1.4.65 2.026.43.625 1.035 1.119 1.81 1.476.776.358 1.66.537 2.652.537
      1.463 0 2.645-.338 3.547-1.012.902-.675 1.353-1.557 1.353-2.647
      0-.649-.148-1.2-.442-1.655-.293-.455-.745-.845-1.353-1.169m6.237-10.064c-.747.731-1.12
      1.768-1.12
      3.11v1.109h-1.845v2.013h1.846V17.42h2.718V7.33h2.461V5.314h-2.46V4.24c0-1.336.647-2.003
      1.947-2.003.394 0 .74.03 1.04.09L136.13.2a6.304 6.304 0 0 0-1.52-.2c-1.342
      0-2.386.365-3.132 1.096m8.547 2.361c.439 0 .786-.127
      1.04-.38.253-.254.38-.566.38-.94
      0-.372-.127-.686-.38-.94-.254-.254-.601-.38-1.04-.38-.44
      0-.785.126-1.036.38a1.29 1.29 0 0 0-.374.94c0
      .374.124.686.374.94.251.253.595.38 1.036.38m4.772 10.942c-.512.715-1.226
      1.073-2.145 1.073-.917
      0-1.632-.352-2.146-1.057-.516-.706-.774-1.68-.774-2.926
      0-1.401.262-2.454.785-3.159.522-.705 1.227-1.057 2.114-1.057.902 0
      1.617.357 2.142 1.072.526.717.789 1.686.789 2.91 0 1.38-.256 2.428-.765
      3.144Zm1.811-7.731c-1.018-1.052-2.343-1.578-3.977-1.578-1.105
      0-2.08.256-2.932.767-.85.511-1.51 1.242-1.979 2.192-.471.951-.704
      2.02-.704 3.206v.134c0 1.894.512 3.41 1.537 4.547 1.026 1.138 2.391 1.707
      4.1 1.707 1.12 0 2.104-.258 2.96-.773.853-.513 1.51-1.24
      1.969-2.18.457-.942.687-2.007.687-3.2l-.01-.638c-.083-1.738-.633-3.132-1.65-4.184Zm-1.471-3.199c.44
      0 .786-.128 1.04-.38.253-.255.38-.568.38-.94
      0-.373-.127-.687-.38-.94-.254-.254-.6-.382-1.04-.382-.44
      0-.787.128-1.04.382a1.275 1.275 0 0 0-.381.94c0
      .372.127.685.38.94.254.252.602.38 1.041.38m7.99
      3.199-.067-1.353h-2.596v12.104h2.72V9.14c.432-.954 1.27-1.43
      2.516-1.43.382 0 .75.029
      1.108.088l.011-2.552c-.254-.104-.574-.156-.962-.156-1.164 0-2.074.526-2.73
      1.578m12.793 3.569h-5.18c.126-.933.418-1.66.876-2.183.46-.522 1.061-.781
      1.807-.781.754 0 1.343.232 1.768.699.424.466.668 1.153.728
      2.062v.203Zm-2.484-5.147a5.2 5.2 0 0 0-2.82.793c-.843.53-1.498 1.272-1.964
      2.223-.466.95-.7 2.03-.7 3.238v.335c0 1.805.537 3.251 1.606 4.337 1.071
      1.085 2.467 1.627 4.191 1.627 1.007 0 1.913-.2 2.724-.599a4.895 4.895 0 0
      0 1.94-1.684l-1.465-1.398c-.782 1.007-1.798 1.51-3.043 1.51-.888
      0-1.625-.29-2.21-.874-.585-.582-.92-1.37-1-2.37h7.908v-1.096c0-1.925-.448-3.412-1.347-4.464-.9-1.052-2.172-1.578-3.82-1.578Zm13.5
      0c-1.454 0-2.63.54-3.524
      1.623l-.078-1.398h-2.562v12.104h2.718V8.805c.538-.992 1.354-1.488
      2.45-1.488.754 0 1.296.184 1.627.553.333.37.498.938.498
      1.706v7.843h2.72v-8c-.045-2.886-1.328-4.329-3.85-4.329m8.157-4.396c-.493
      0-.87.14-1.136.417-.265.278-.397.628-.397 1.048 0 .414.132.758.397
      1.031.266.276.643.413 1.136.413.492 0 .872-.137
      1.141-.413.268-.273.403-.617.403-1.031
      0-.42-.135-.77-.403-1.048-.27-.277-.649-.417-1.141-.417m-1.366
      16.725h2.72V5.315h-2.72zM195.627 5.09c-1.454 0-2.628.54-3.522
      1.623l-.078-1.398h-2.563v12.104h2.718V8.805c.538-.992 1.355-1.488
      2.45-1.488.753 0 1.297.184 1.629.553.33.37.497.938.497
      1.706v7.843h2.718v-8c-.044-2.886-1.328-4.329-3.849-4.329m13.901
      8.926c-.53.943-1.342 1.411-2.44 1.411-.879
      0-1.567-.348-2.061-1.046-.497-.697-.746-1.652-.746-2.869
      0-1.38.249-2.423.746-3.132.494-.709 1.19-1.063 2.084-1.063 1.098 0
      1.903.458 2.417
      1.376v5.323Zm.146-7.483c-.791-.962-1.877-1.443-3.256-1.443-1.462
      0-2.635.562-3.518 1.685-.885 1.122-1.327 2.622-1.327 4.501 0 1.992.45
      3.551 1.349 4.678.898 1.126 2.057 1.69 3.474 1.69 1.305 0 2.349-.437
      3.132-1.31v.817c0 .947-.26 1.664-.783
      2.152-.522.49-1.234.734-2.137.734-1.224 0-2.253-.497-3.088-1.488l-1.287
      1.633c.44.597 1.085 1.08 1.93 1.45a6.45 6.45 0 0 0 2.602.553c1.678 0
      3.011-.458 3.999-1.376.988-.917 1.483-2.17 1.483-3.758V5.315h-2.451l-.122
      1.218Zm12.767 3.704h-5.18c.126-.933.42-1.66.879-2.183.457-.522 1.06-.781
      1.806-.781.753 0 1.342.232 1.768.699.425.466.667 1.153.727
      2.062v.203Zm-2.484-5.147a5.205 5.205 0 0 0-2.82.793c-.842.53-1.496
      1.272-1.962 2.223-.467.95-.7 2.03-.7 3.238v.335c0 1.805.535 3.251 1.606
      4.337 1.07 1.085 2.467 1.627 4.189 1.627 1.007 0 1.915-.2 2.724-.599a4.878
      4.878 0 0 0 1.94-1.684l-1.464-1.398c-.784 1.007-1.797 1.51-3.043 1.51-.887
      0-1.624-.29-2.21-.874-.586-.582-.92-1.37-1-2.37h7.909v-1.096c0-1.925-.45-3.412-1.349-4.464-.898-1.052-2.172-1.578-3.82-1.578Zm13.403
      0c-1.454 0-2.629.54-3.524
      1.623l-.079-1.398h-2.561v12.104h2.718V8.805c.538-.992 1.353-1.488
      2.451-1.488.753 0 1.296.184 1.628.553.331.37.497.938.497
      1.706v7.843h2.719v-8c-.045-2.886-1.327-4.329-3.849-4.329"
      />
    </svg>
  )
}
