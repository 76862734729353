import fetchAPI from "./fetchAPI"

export async function createGravityFormEntry(id, data, token) {
  const { createGravityFormEntry } = await fetchAPI(
    `mutation($id: ID!, $data: JSON!, $token: String!) {
          createGravityFormEntry(id: $id, data: $data, token: $token){
            error
            data
          }
        }`,
    {
      variables: { id, data: JSON.stringify(data), token }
    }
  )

  return createGravityFormEntry
}

export async function requestNameCollectionCount(id) {
  const { fetchNameCollectionCount } = await fetchAPI(
    `query($id: ID!) {
        fetchNameCollectionCount(id: $id) {
          count
        }
      }`,
    {
      variables: { id }
    }
  )

  return fetchNameCollectionCount
}

export async function uploadToGravityForm(
  form_id,
  field_id,
  gform_unique_id,
  file
) {
  const GFapiURL =
    process.env.NEXT_PUBLIC_WP_JSON_API_URL + "triggerfish/gravity-forms"
  const splitedFieldId = field_id.split("_")
  let formData = new FormData()
  formData.append("file", file, file.name)
  formData.append("form_id", form_id)
  formData.append("gform_unique_id", gform_unique_id)
  formData.append("original_filename", file.name)
  formData.append("name", file.name)
  formData.append("field_id", splitedFieldId[1])

  try {
    const res = await fetch(GFapiURL + "/upload", {
      method: "POST",
      body: formData
    })
    const json = await res.text()
    return JSON.parse(json)
  } catch (error) {
    console.log("error", error)
    return error
  }
}

export async function fetchGravityFormResult(
  form,
  calculation,
  entry,
  personal
) {
  const { fetchGravityFormResult } = await fetchAPI(
    `query($form: ID!, $calculation: String!, $entry: String!, $personal: String) {
        fetchGravityFormResult(form: $form, calculation: $calculation, entry: $entry, personal: $personal) {
          error
          data
        }
      }`,
    {
      variables: { form, calculation, entry, personal }
    }
  )

  return fetchGravityFormResult
}

export async function sendCalculationResults({
  form,
  token,
  email,
  entry,
  personal,
  newsletter
}) {
  const { requestResultMail } = await fetchAPI(
    `mutation($form: ID!, $token: String!, $email: String!, $entry: String!, $personal: String!, $newsletter: Boolean!) {
        requestResultMail(form: $form, token: $token, email: $email, entry: $entry, personal: $personal, newsletter: $newsletter) {
            error,
            message
        }
      }`,
    {
      variables: {
        form,
        token,
        email,
        entry,
        personal,
        newsletter
      }
    }
  )

  return requestResultMail
}
