import Archive from "@/templates/archive/archive"
import Article from "@/templates/article/article"
import Campaign from "@/templates/campaign/campaign"
import Custom from "@/templates/custom/custom"
import Event from "@/templates/event/event"
import Gift from "@/templates/gift/gift"
import Page from "@/templates/page/page"

import Error from "@/templates/error/error"
import Protected from "@/templates/protected/protected"

export const templateMapping = {
  archive: Archive,
  article: Article,
  campaign: Campaign,
  custom: Custom,
  error: Error,
  event: Event,
  gift: Gift,
  page: Page,
  protected: Protected
}
