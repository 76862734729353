export default function LinkedIn({ className = "w-4 fill-current" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={className}>
      <path d="M0 6.471v13.218h4.282V6.471H0zM2.422 0C.958 0 0 .987 0 2.283c0 1.27.93 2.285 2.367 2.285h.027c1.493 0 2.423-1.016 2.423-2.285C4.79.987 3.887 0 2.422 0zM15.07 6.471c-2.273 0-3.291 1.283-3.86 2.183V6.782H6.928c.056 1.24 0 13.218 0 13.218h4.282v-7.382c0-.395.028-.79.141-1.072.31-.79 1.014-1.607 2.197-1.607 1.55 0 2.17 1.213 2.17 2.989V20H20v-7.58c0-4.06-2.112-5.949-4.93-5.949z" />
    </svg>
  )
}
