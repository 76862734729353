import fetchAPI from "./fetchAPI"

export async function fetchSSOToken(token, captcha, service) {
  const { fetchSSOToken } = await fetchAPI(
    `query($token: String!, $captcha: String!, $service: String!) {
      fetchSSOToken(token: $token, captcha: $captcha, service: $service) {
        cookie,
        status,
        token,
        url,
        domain
      }
    }`,
    {
      variables: { token, captcha, service }
    },
    true
  )
  return fetchSSOToken
}
