import { useEffect, useState } from "react"
import Main from "@/components/wrappers/main"
import { useHasHero } from "@/lib/helpers/template"
import {
  usePathData,
  usePropsObject,
  useAdminBar
} from "@/lib/contexts/appContext"
import Blocks from "@/components/Blocks"
import classNames from "classnames"
import styles from "./campaign.module.css"

export default function Campaign() {
  const pathData = usePathData()
  const hasHero = useHasHero()
  const adminBar = useAdminBar()
  const { theme, sidebarContent } = usePropsObject()
  const hasSidebar = pathData.template === "campaign" && Boolean(sidebarContent)
  const [classes, setClasses] = useState([])

  useEffect(() => {
    const classes = [
      styles.main,
      theme?.bgColor ? `theme-bg-${theme.bgColor}` : "",
      theme?.txtColor ? `theme-txt-${theme.txtColor}` : "",
      theme?.accentColor ? `theme-accent-${theme.accentColor}` : "",
      theme?.heroGradient ? `theme-hero-gradient-${theme.bgColor}` : "",
      {
        "has-sidebar": hasSidebar,
        "no-hero": !hasHero,
        "has-admin-bar": adminBar
      }
    ]

    setClasses(classes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData])

  let settings = {}

  if (pathData.path && pathData.path.split("/").filter(Boolean).length < 3) {
    settings = {
      "theme/hero": { hideBreadcrumbs: true }
    }
  }

  return (
    <Main className={classNames(classes)}>
      <Blocks blocks={pathData.content} settings={settings} />
    </Main>
  )
}
