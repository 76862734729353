import Blocks from "@/components/Blocks"
import Breadcrumbs from "@/components/base/breadcrumbs/breadcrumbs"
import SearchPanel from "@/components/common/search-panel/search-panel"
import Main from "@/components/wrappers/main"
import SSOModal from "@/components/common/member/sso-modal/sso-modal"
import {
  useAdminBar,
  useAppContext,
  usePathData,
  usePropsObject
} from "@/lib/contexts/appContext"
import { ArchiveProvider } from "@/lib/contexts/archiveContext"
import { useHasHero, useIsStart } from "@/lib/helpers/template"
import classNames from "classnames/bind"
import { useEffect, useState } from "react"
import styles from "./page.module.css"

export default function Page() {
  const pathData = usePathData()
  const appContext = useAppContext()
  const object = usePropsObject()
  const isStart = useIsStart()
  const hasHero = useHasHero()
  const hasSearch = object.searchPanel ?? false
  const cx = classNames.bind(styles)
  const adminBar = useAdminBar()
  const [classes, setClasses] = useState({
    main: true,
    white: object?.pageColor === "white",
    "summer-green": object?.pageColor === "summer-green"
  })
  const [style, setStyle] = useState({})

  useEffect(() => {
    const pageColor = object?.pageColor ?? ""
    const hasCustomColor = pageColor.startsWith("#")

    const current = {
      main: true,
      white: pageColor === "white",
      "summer-green": pageColor === "summer-green",
      "has-custom-bg-color": hasCustomColor
    }

    setClasses({
      ...current,
      "no-hero": !hasHero,
      "has-search": hasSearch,
      "has-admin-bar": adminBar
    })

    if (hasCustomColor) {
      setStyle({ backgroundColor: pageColor })
    } else {
      setStyle({})
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData])

  return (
    <Main className={cx(classes)} style={style}>
      {object.searchPanel && (
        <ArchiveProvider context="school" object={object}>
          <SearchPanel />
        </ArchiveProvider>
      )}
      {!isStart && !hasHero && (
        <Breadcrumbs
          crumbs={appContext.breadcrumbs}
          className="mt-4 mb-5 lg:ml-0"
        />
      )}
      <Content
        isArchive={object?.isArchive}
        object={object}
        pathData={pathData}
      />
      <SSOModal />
    </Main>
  )
}

export function Content({ isArchive, object, pathData }) {
  if (isArchive) {
    return (
      <ArchiveProvider context="on-page" object={object}>
        <Blocks blocks={pathData.content} />
      </ArchiveProvider>
    )
  }

  return <Blocks blocks={pathData.content} />
}
