import Block from "@/components/wrappers/block"

export default function NotFound({ block, pathData }) {
  let info = false

  if (block) {
    info = (
      <>
        <strong>Block &quot;{block.name}&quot; not found</strong>
        <span className="block mt-2 overflow-y-scroll font-mono whitespace-pre max-h-72">
          {JSON.stringify(block.attrs, null, 2)}
        </span>
      </>
    )
  }

  if (block === undefined && pathData) {
    info = (
      <>
        <strong>Template &quot;{pathData.template}&quot; not found</strong>
        <span className="block mt-2 font-mono whitespace-pre-line">
          {JSON.stringify(pathData, null, 2)}
        </span>
      </>
    )
  }

  if (!info) {
    info = "Ett okänt fel uppstod."
  }

  return (
    <Block gap="small" block={block}>
      <div className="p-8 text-white bg-red-110">{info}</div>
    </Block>
  )
}
