import { usePostType } from "@/lib/helpers/template"
import { useEffect, useState } from "react"
import { Spinner } from "@/common/loading/spinner"
import { default as ProgressBarElem } from "@/common/progress-bar/progress-bar"
import { usePropsObject } from "@/lib/contexts/appContext"
import { useNameCollectionContext } from "@/lib/contexts/nameCollectionContext"

export default function ProgressBar({ block, settings }) {
  const { total, text, entries, showAt } = block.attrs
  const { count, refreshCount } = useNameCollectionContext()
  const [stateCount, setStateCount] = useState(entries)
  const postType = usePostType()
  const { theme: campaignTheme } = usePropsObject()

  let colorTheme =
    block.attrs.theme === "none" &&
    postType === "campaign" &&
    campaignTheme.accentColor
      ? campaignTheme.accentColor
      : block.attrs.theme

  useEffect(() => {
    if (count) {
      setStateCount(count)
    }
  }, [count])

  useEffect(() => {
    refreshCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!total) {
    return null
  }

  const countText = !block.attrs.hideTotal
    ? "av " + total.toLocaleString("sv-SE") + " " + text
    : " " + text

  const darkMode = ["white"].includes(colorTheme)

  if (showAt > stateCount) {
    return null
  }

  if (!count && !settings?.isFloating) {
    return (
      <Spinner className="mb-4 mr-4 opacity-50" spinColor="rgb(0, 0, 0, 0.5)" />
    )
  }

  return (
    <ProgressBarElem
      theme={colorTheme}
      count={stateCount}
      total={total}
      text={countText}
      align="left"
      darkMode={darkMode}
      isFloating={settings?.isFloating}
      textOnly={block.attrs.textOnly}
    />
  )
}
