import Main from "@/components/wrappers/main"
import styles from "@/templates/error/error.module.css"
import Block from "@/components/wrappers/block"

export function Error() {
  return (
    <Main className={styles.noHero}>
      <Block width="column">
        <h1 className="text-6xl">404</h1>
        <p>Ja, nu blev det ju tokigt.</p>
      </Block>
    </Main>
  )
}

export default Error
