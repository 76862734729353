import { motion } from "framer-motion"
import classNames from "classnames"
import styles from "./progress-bar.module.css"

export default function ProgressBar({
  theme,
  count,
  total,
  text,
  units = null,
  showPercentage = true,
  align = "center",
  darkMode = false,
  isFloating = false,
  textOnly = false
}) {
  const percentage = (count / total) * 100

  return (
    <div
      className={classNames(styles.wrapper, {
        "is-floating": isFloating,
        "no-progress-bar": textOnly
      })}>
      <TextBlock count={count} text={text} align={align} units={units} />
      <Bar
        count={count}
        theme={theme}
        percentage={percentage}
        showPercentage={showPercentage}
        textOnly={textOnly}
        darkMode={darkMode}
      />
    </div>
  )
}

const TextBlock = ({ count, text, units, align }) => {
  const current = [count.toLocaleString("sv-SE"), units]

  return (
    <div
      className={classNames("text-wrapper", {
        right: align === "right",
        center: align === "center"
      })}>
      <span className="current-count">{current.filter(Boolean).join(" ")}</span>
      <span>{text}</span>
    </div>
  )
}

const Bar = ({ theme, percentage, showPercentage, textOnly, darkMode }) => {
  if (textOnly) {
    return null
  }

  return (
    <div
      className={classNames("progress-bar", theme, {
        dark: darkMode,
        light: !darkMode
      })}>
      <motion.div
        initial={{ width: percentage * 0.2 + "%" }}
        transition={{
          duration: 2,
          type: "tween"
        }}
        animate={{ width: percentage + "%" }}
        className="filler">
        {showPercentage && Math.round(percentage) + "%"}
      </motion.div>
    </div>
  )
}
