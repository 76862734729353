export default function CheckmarkRounded({
  className = "",
  fillColor = "#101010"
}) {
  return (
    <svg
      viewBox="0 0 10 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={`${className}`}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-440.000000, -585.000000)"
          fill={fillColor}
          fillRule="nonzero">
          <g transform="translate(415.000000, 429.000000)">
            <g transform="translate(0.000000, 140.000000)">
              <path d="M33.2592597,16.3282085 C33.6302799,15.9191089 34.262692,15.8882395 34.6717915,16.2592597 C35.0494219,16.6017399 35.1047733,17.1669445 34.819237,17.5736404 L34.7407403,17.6717915 L29.2992255,23.6717915 C28.9306959,24.0781449 28.3113854,24.1071701 27.90703,23.7588672 L27.817745,23.6717915 L25.2592597,20.8507184 C24.8882395,20.4416188 24.9191089,19.8092068 25.3282085,19.4381866 C25.7058388,19.0957064 26.2737473,19.0956647 26.6507008,19.4194526 L26.7407403,19.5071353 L28.558,21.511 L33.2592597,16.3282085 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
