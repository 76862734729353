import useMenuItems from "@/lib/helpers/menu"
import Menu from "@/components/common/menu/simple"
import MemberMenuItem from "@/components/base/member/menuItem"
import styles from "./menu.module.css"

export default function Primary() {
  const location = "primary-footer"
  const items = useMenuItems(location)

  return (
    <div className={styles.wrapper}>
      <Menu menuClasses={styles.menu} items={items} location={location} />
      <MemberMenuItem context="footer" />
    </div>
  )
}
