import { useCopy } from "@/lib/contexts/appContext"

export default function Content() {
  const copy = useCopy()
  const { content } = copy.footer
  // From tab "Innehåll"
  // As we are not using it right now, we skip the refactoring

  if (!content || content.length === 0) {
    return null
  }

  return null
}
