export default function Menu({ className = "" }) {
  return (
    <svg
      width="30"
      height="20"
      viewBox="0 0 30 20"
      className={`fill-current ${className}`}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M30 18v2H0v-2h30zm0-9v2H0V9h30zm0-9v2H0V0h30z" />
    </svg>
  )
}
