import { useEffect, useState } from "react"
import { useHasHero } from "@/lib/helpers/template"
import {
  usePathData,
  usePropsObject,
  useAdminBar
} from "@/lib/contexts/appContext"
import Breadcrumbs from "@/components/base/breadcrumbs/breadcrumbs"
import Block from "@/components/Block"
import Blocks from "@/components/Blocks"
import { default as BlockWrapper } from "@/components/wrappers/block"
import Main from "@/components/wrappers/main"
import classNames from "classnames/bind"
import styles from "./gift.module.css"
import campaignStyles from "@/templates/campaign/campaign.module.css"

export function Page() {
  const pathData = usePathData()
  const cx = classNames.bind(styles)
  const heroFormat = useHasHero()
  const adminBar = useAdminBar()
  const [classes, setClasses] = useState({
    main: true
  })

  useEffect(() => {
    setClasses({
      ...classes,
      "no-hero": !heroFormat,
      "has-admin-bar": adminBar
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData])

  const object = usePropsObject()
  const crumbs = [
    { label: "Stöd oss", uri: "/stod-oss/" },
    { label: "Gåvor", uri: "/stod-oss/gavor/" },
    { label: object.title, uri: null }
  ]

  let { giftPageHeader, checkoutForm, theme } = object
  let { thumbnail, checkoutFormLight } = giftPageHeader

  const themeColors = [
    campaignStyles.main,
    theme?.bgColor ? `theme-bg-${theme.bgColor}` : "",
    theme?.txtColor ? `theme-txt-${theme.txtColor}` : "",
    theme?.accentColor ? `theme-accent-${theme.accentColor}` : "",
    theme?.heroGradient ? `theme-hero-gradient-${theme.bgColor}` : ""
  ]

  return (
    <Main className={cx(classes, themeColors)}>
      <Breadcrumbs crumbs={crumbs} className="my-4 lg:ml-0" />
      <Block
        block={checkoutFormLight}
        settings={{
          width: "bleed",
          image: thumbnail.attrs.image
        }}
      />
      <BlockWrapper width="bleed" className="bg-white">
        <div className="py-10 sm:py-12.5 lg:py-20 bg-white xl:text-xl xl:leading-8">
          <Blocks blocks={pathData.content} />
        </div>
      </BlockWrapper>
      <Block block={checkoutForm} />
    </Main>
  )
}

export default Page
